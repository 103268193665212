
function ProjectsPage() {
    return(
        <>
            <h1>Projects</h1>
        </>

    );
}

export default ProjectsPage;