import {Blockquote, Rating} from "flowbite-react";
import {RiFileDownloadFill} from "react-icons/ri";
import {Link} from "react-router-dom";

const HomeRating = (props) => {
    const author = props.author;
    const author_company = props.author_company;
    const author_role = props.author_role;
    const rating = props.rating;
    const attach = props.attach ? <Link to={`/references/${props.attach}`} target="_blank" download><RiFileDownloadFill size={25} className="cursor-pointer" /></Link> : '';
    return (
      <>
          <div className="basis-1/3 m-5 ">
              <figure className="max-w-screen-md">
                  <div className="mb-4 flex items-center">
                      <Rating size="md">
                          <Rating.Star/>
                          <Rating.Star/>
                          <Rating.Star/>
                          <Rating.Star/>
                          <Rating.Star/>
                          {attach}
                      </Rating>
                  </div>
                  <Blockquote>
                      <p className="text-lg text-gray-700">
                          "{rating}"
                      </p>
                  </Blockquote>
                  <div className="basis-1/3">
                      <figcaption className="mt-6 flex items-center space-x-3">
                          <div className="flex items-center divide-x-2 divide-gray-300">
                              <cite className="pr-3 font-medium text-gray-900 dark:text-white">{author}</cite>
                              <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">{author_role} @{author_company}</cite>

                          </div>
                      </figcaption>
                  </div>
              </figure>
          </div>
      </>
    );
}

export default HomeRating;