import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import ServicesPage from "./pages/Services";
import ProjectsPage from "./pages/Projects";
import ContactsPage from "./pages/Contacts";
import RootLayout from "./RootLayout";
import ErrorPage from "./pages/Error";
import WorksPage from "./pages/Works";
import ReferencesPage from "./pages/References";
import ServiziPage from "./pages/Servizi";

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <HomePage />
            },
            {
                path: '/about',
                element: <AboutPage />
            },
            {
                path: '/services',
                element: <ServicesPage />
            },
            {
                path: '/servizi',
                element: <ServiziPage />
            },
            {
                path: '/projects',
                element: <ProjectsPage />
            },
            {
                path: '/works',
                element: <WorksPage />
            },
            {
                path: '/contacts',
                element: <ContactsPage />
            },
            {
                path: '/references',
                element: <ReferencesPage />
            },
        ]
    },

])
function App() {
  return <RouterProvider router={router} />
}

export default App;
