import { useEffect } from "react";
import {BiLogoLinkedinSquare} from "react-icons/bi";
import {MdOutlineAlternateEmail} from "react-icons/md";
import {FaBuildingColumns} from "react-icons/fa6";
import {FaMobileScreen} from "react-icons/fa6";
import {FaGithub} from "react-icons/fa6";
import {Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import {Link} from "react-router-dom";
import {MdOutlineContactSupport} from "react-icons/md";
import DocumentMeta from 'react-document-meta';

function ContactsPage() {
    useEffect(() => {
        document.title = 'Maurizio Brioschi contacts: where find me and how stay connected';
    }, []);
    const meta = {
        meta: {
            name: {
                description: 'Find Maurizio Brioschi address, email, phone and social networks. Contact me today to discuss how I can support your technical projects and help you achieve your goals.',
                keywords: 'email, address, social networks, mobile, phone, linkedin, github'
            }
        }
    };
    return (
        <>
            <DocumentMeta {...meta} />
            <Breadcrumb aria-label="Solid background breadcrumb example"
                        className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                <Breadcrumb.Item href="/" icon={HiHome}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/contacts">Contacts</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="font-impact text-3xl mb-5 mt-5 ml-2"><MdOutlineContactSupport/>How contact me?</h1>
            <div className="flex ml-2 mr-2">
                <FaBuildingColumns size={20} className="mr-2 md:mr-5"/>
                Maurizio Brioschi - via Amsicora 46 - 09072 Cabras (OR) - Sardegna - Italy
            </div>
            <div className="flex mt-2 ml-2 mr-2">
                <MdOutlineAlternateEmail size={20} className="mr-2 md:mr-5"/>
                <a href="mailto:info@mauriziobrioschi.com">info@mauriziobrioschi.com</a>
            </div>
            <div className="flex mt-2 ml-2 mr-2">
                <FaMobileScreen size={20} className="mr-2 md:mr-5"/><a href="tel:+393515699197">+39 351 569 91 97</a>
            </div>
            <div className="flex mt-2 ml-2 mr-2">
                <Link to="https://www.linkedin.com/in/mauriziobrioschi/" target="_blank">
                    <BiLogoLinkedinSquare size={20} className="text-blue-800 mr-2 md:mr-5"/></Link>
                <Link to="https://www.linkedin.com/in/mauriziobrioschi/" target="_blank">https://www.linkedin.com/in/mauriziobrioschi/</Link>
            </div>
            <div className="flex mt-2 ml-2 mr-2">
                <Link to="https://github.com/mbrioski" target="_blank"><FaGithub size={20} className="mr-2 md:mr-5"/></Link>
                <Link to="https://github.com/mbrioski" target="_blank">https://github.com/mbrioski</Link>
            </div>
            <div className="ml-2 mr-2 mt-5 mb-5">
                <iframe className="w-full min-h-[500px] rounded"
                        title="Maurizio Brioschi address"
                        id="google_map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3070.12243208639!2d8.535083082797685!3d39.93019024438759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12dd9e8c622fd7c1%3A0x88ee76d5c55ee9b0!2sVia%20Amsicora%2C%2046%2C%2009072%20Cabras%20OR!5e0!3m2!1sit!2sit!4v1702280914923!5m2!1sit!2sit"
                        allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>

    );
}

export default ContactsPage;