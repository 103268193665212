import {Navbar} from 'flowbite-react';

function Nav() {
    return (
        <Navbar fluid rounded>
            <Navbar.Brand href="/">
                <span
                    className="font-impact text-3xl">Maurizio Brioschi</span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Navbar.Link href="/">Home</Navbar.Link>
                <Navbar.Link href="/about">About</Navbar.Link>
                <Navbar.Link href="/services" >Services</Navbar.Link>
                <Navbar.Link href="/references" >References</Navbar.Link>
                {/*<Navbar.Link href="/projects" className="font-source">Projects</Navbar.Link>*/}
                {/*<Navbar.Link href="/works">Works</Navbar.Link>*/}
                <Navbar.Link href="/contacts">Contacts</Navbar.Link>
            </Navbar.Collapse>
        </Navbar>
    );
}


export default Nav;

