import { Card } from "flowbite-react";


const WorkCard = () => {
    return (
        <Card
            className="max-w-sm"
            imgAlt="Meaningful alt text for an image that is not purely decorative"
            imgSrc="/images/blog/image-1.jpg"
        >
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Teatro.it
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
                The main portal about theaters in Italy
                <ul>
                    <li>Managed a team of 5 </li>
                    <li>Migrate</li>
                </ul>
            </p>
        </Card>
    );
}

export default WorkCard;