import { useEffect } from "react";
import {Breadcrumb, Timeline} from "flowbite-react";
import {MdWork} from "react-icons/md";
import {FaUserGraduate} from "react-icons/fa";
import {HiHome} from "react-icons/hi";
import {GiStairsGoal} from "react-icons/gi";
import {Link} from "react-router-dom";
import {RiFileDownloadFill} from "react-icons/ri";
import {BiLogoLinkedinSquare} from "react-icons/bi";
import {FaGithub} from "react-icons/fa6";
import {List} from "flowbite-react";
import {HiCheckCircle} from "react-icons/hi";
import {RiFlightTakeoffLine} from "react-icons/ri";
import {GiNewBorn} from "react-icons/gi";
import {GiRing} from "react-icons/gi";
import DocumentMeta from 'react-document-meta';

function AboutPage() {
    useEffect(() => {
        document.title = 'Maurizio Brioschi about: all important steps in my career to became a team leader, software architect and software engineer';
    }, []);
    const meta = {
        meta: {
            name: {
                description: 'All the steps of Maurizio Brioschi career on the path to became a team leader, software architect and engineer. Download my CV to discover all companies, studies and jobs done.',
                keywords: 'freelancer, cv, curriculum vitae, career, jobs, companies, studies, education, family'
            }
        }
    };
    return (
        <>
            <DocumentMeta {...meta} />
            <Breadcrumb aria-label="Solid background breadcrumb example"
                        className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                <Breadcrumb.Item href="/" icon={HiHome}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/about">About</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <h1 className="font-impact text-3xl md:mb-5 mt-5 ml-2"><GiStairsGoal/>About my career</h1>
                <div className="inline flex mt-1 mb-2 md:hidden">
                    <Link to="https://www.linkedin.com/in/mauriziobrioschi/" target="_blank">
                        <BiLogoLinkedinSquare size={40} className="text-blue-800 mr-5"/></Link>
                    <Link to="https://github.com/mbrioski" target="_blank"><FaGithub size={40} className="mr-5"/></Link>
                    <Link to="/files/CVEU_2024.pdf" target="_blank" download><RiFileDownloadFill
                        className="cursor-pointer text-right" size={40}/></Link>
                </div>
                <Timeline className="ml-2 mr-5">
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> February 2023 -
                                actual</Timeline.Time>
                            <Timeline.Title>Software/Solution Architect and Technical Lead</Timeline.Title>
                            <Timeline.Body>
                                <p><Link to="https://www.mazzmedia.com/it/" target="_blank" className="text-green-800">Mazzmedia
                                    s.r.l.</Link></p>
                                <p className="italic">Milan - Italy</p>
                                <div className="mt-2">
                                    <List>
                                        <List.Item icon={HiCheckCircle}>Technical leader: managing 4 developers and
                                            customer requirements</List.Item>
                                        <List.Item icon={HiCheckCircle}>Software and infrastructure architect on cloud
                                            (AWS) using AWS ECS
                                            architecture
                                        </List.Item>
                                        <List.Item icon={HiCheckCircle}>Docker development environment
                                            maintainer</List.Item>
                                        <List.Item icon={HiCheckCircle}>Pipeline creation for continuous
                                            delivery</List.Item>
                                        <List.Item icon={HiCheckCircle}>Responsible for projects workflow and
                                            Agile</List.Item>
                                        <List.Item icon={HiCheckCircle}>Responsible for code quality via code reviews,
                                            testing, logging and
                                            performance monitoring
                                        </List.Item>
                                        <List.Item icon={HiCheckCircle}>Development (Laravel, Reactjs, Vuejs, Wordpress,
                                            Prestashop, Magento)</List.Item>
                                        <List.Item icon={HiCheckCircle}>Website optimisations</List.Item>
                                    </List>
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> October 2022 - November
                                2023</Timeline.Time>
                            <Timeline.Title>Chief Technology Officer</Timeline.Title>
                            <Timeline.Body>
                                <p><Link to="https://www.getpalco.com" target="_blank" className="text-green-800">Palco
                                    ltd</Link></p>
                                <p className="italic">London - UK</p>
                                <div className="mt-2">
                                    <List>
                                        <List.Item icon={HiCheckCircle}>Saas maintainer on cloud (Linode -
                                            Aws)</List.Item>
                                        <List.Item icon={HiCheckCircle}>Tech leader and developer (Laravel -
                                            Reactjs)</List.Item>
                                    </List>
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> February 2022 - January
                                2023</Timeline.Time>
                            <Timeline.Title>Senior software engineer</Timeline.Title>
                            <Timeline.Body>
                                <p><Link to="https://newporttakkt.com" target="_blank" className="text-green-800">Neporttakkt
                                    GmbH</Link></p>
                                <p className="italic">Stuttgart - DE</p>
                                <div className="mt-2">
                                    <List>
                                        <List.Item icon={HiCheckCircle}>Software architecture</List.Item>
                                        <List.Item icon={HiCheckCircle}>Shopware development</List.Item>
                                        <List.Item icon={HiCheckCircle}>Software optimisations</List.Item>
                                        <List.Item icon={HiCheckCircle}>Code reviews</List.Item>
                                    </List>
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><GiNewBorn size={25} className="text-blue-600"/>October 2022</Timeline.Time>
                            <Timeline.Title>My son Manuel born</Timeline.Title>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><FaUserGraduate size={20} className="text-yellow-400"/> June
                                2020</Timeline.Time>
                            <Timeline.Title>Adobe Certified Expert-Adobe Commerce Cloud Developer</Timeline.Title>
                            <Timeline.Body>
                                <p>Adobe</p>
                                <p><Link
                                    to="https://www.credly.com/badges/c9a2096c-d092-4703-b792-cfff4766f1b8/linked_in_profile"
                                    target="_blank" className="text-green-800">Certification link</Link></p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><RiFlightTakeoffLine size={25} className="text-sky-800"/>August
                                2020</Timeline.Time>
                            <Timeline.Title>Moved to Sardinia</Timeline.Title>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> July 2019 - January
                                2022</Timeline.Time>
                            <Timeline.Title>Technical Leader - Senior Engineer</Timeline.Title>
                            <Timeline.Body>
                                <p><Link to="https://www.mediawave.de/" target="_blank" className="text-green-800">mediawave
                                    internet solutions GmbH</Link></p>
                                <p className="italic">München - DE</p>
                                <div className="mt-2">
                                    <List>
                                        <List.Item icon={HiCheckCircle}>Technical/team leader: managing 4 developers and
                                            customer requirements</List.Item>
                                        <List.Item icon={HiCheckCircle}>Part of the recruitment process, performing
                                            interviews for right candidates.
                                        </List.Item>
                                        <List.Item icon={HiCheckCircle}>Responsible for code quality via code reviews,
                                            testing, logging and performance
                                            monitoring.</List.Item>
                                        <List.Item icon={HiCheckCircle}>Magento cloud maintainer</List.Item>
                                        <List.Item icon={HiCheckCircle}>Docker development environment
                                            maintainer</List.Item>
                                        <List.Item icon={HiCheckCircle}>Software architecture of Magento 2 projects
                                            involving: RabbitMq, RestApi, CRM ntegrations, GraphQL development,
                                            Elasticsearch
                                        </List.Item>
                                        <List.Item icon={HiCheckCircle}>Pipeline creation for continuous delivery in
                                            gitlab</List.Item>
                                        <List.Item icon={HiCheckCircle}>Magento2 development</List.Item>
                                        <List.Item icon={HiCheckCircle}>Product configurator development and
                                            architecture in vuejs completely integrated
                                            in Dynamics365</List.Item>
                                        <List.Item icon={HiCheckCircle}>React development for PWA Studio
                                            project</List.Item>
                                    </List>
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> April 2019 - July
                                2019</Timeline.Time>
                            <Timeline.Title>Senior Backend Engineer</Timeline.Title>
                            <Timeline.Body>
                                <p><Link to="https://www.vitafy.de" target="_blank" className="text-green-800">vitafy
                                    GmbH</Link></p>
                                <p className="italic">München - DE</p>
                                <div className="mt-2">
                                    <List>
                                        <List.Item icon={HiCheckCircle}>Magento 1 developer</List.Item>
                                        <List.Item icon={HiCheckCircle}>Nodejs Developer
                                        </List.Item>
                                        <List.Item icon={HiCheckCircle}>Dev Ops task related to Docker and gitlab-ci
                                            optimizations</List.Item>
                                    </List>
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> November 2018 - March
                                2019</Timeline.Time>
                            <Timeline.Title>Senior Software Engineer</Timeline.Title>
                            <Timeline.Body>
                                <p><Link to="https://www.burda.com" target="_blank" className="text-green-800">Hubert
                                    Burda Media GmbH</Link></p>
                                <p className="italic">München - DE</p>
                                <div className="mt-2">
                                    <List>
                                        <List.Item icon={HiCheckCircle}>DevOps: new development environment with Docker
                                            and Docker images maintainer</List.Item>
                                        <List.Item icon={HiCheckCircle}>Drupal developer
                                        </List.Item>
                                    </List>
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><FaUserGraduate size={20} className="text-yellow-400"/> February
                                2018</Timeline.Time>
                            <Timeline.Title>Getting Started with Google Kubernetes Engine</Timeline.Title>
                            <Timeline.Body>
                                <p>Google Cloud</p>
                                <p><Link to="https://www.coursera.org/account/accomplishments/verify/9UYPX69QUBUL"
                                         target="_blank" className="text-green-800">Certification link</Link></p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> April 2017 - May
                                2018</Timeline.Time>
                            <Timeline.Title>Senior Software Engineer</Timeline.Title>
                            <Timeline.Body>
                                <p><Link to="https://www.limango.de" target="_blank" className="text-green-800">limango
                                    GmbH - A member of the otto group</Link></p>
                                <p className="italic">München - DE</p>
                                <div className="mt-2">
                                    <List>
                                        <List.Item icon={HiCheckCircle}>Senior Backend developer: Laravel, MySql,
                                            Javascript, RabbitMq, Elasticsearch.</List.Item>
                                        <List.Item icon={HiCheckCircle}>Supplier order micro service</List.Item>
                                        <List.Item icon={HiCheckCircle}>Responsibility for code quality: tests, code
                                            style, documentation.</List.Item>
                                        <List.Item icon={HiCheckCircle}>In API core member group</List.Item>
                                    </List>
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> October 2015 - March
                                2017</Timeline.Time>
                            <Timeline.Title>Senior Developer</Timeline.Title>
                            <Timeline.Body>
                                <p><Link to="https://www.mothership.de" target="_blank" className="text-green-800">Mothership
                                    GmbH</Link></p>
                                <p className="italic">München - DE</p>
                                <div className="mt-2">
                                    <List>
                                        <List.Item icon={HiCheckCircle}>Backend developer: website optimizations through
                                            the creation of new extensions and modules</List.Item>
                                        <List.Item icon={HiCheckCircle}>Magento Developer</List.Item>
                                        <List.Item icon={HiCheckCircle}><Link to="https://www.exxpozed.de/"
                                                                              target="_blank"
                                                                              className="text-green-800">Exxpozed shop
                                            refactory</Link>: main responsibilities are the complete data import and
                                            synchronization module from a PIM System into Magento Database; development
                                            of a module to manage Magento database into elasticsearch; library to
                                            schedule jobs inside Rabbitmq; emarsys integration into the system for
                                            marketing; PHP7 fixes.</List.Item>
                                        <List.Item icon={HiCheckCircle}>Company core libraries development and
                                            mainteiner: <Link to="https://github.com/mothership-gmbh" target="_blank"
                                                              className="text-green-800">https://github.com/mothership-gmbh</Link></List.Item>
                                        <List.Item icon={HiCheckCircle}>Code reviews</List.Item>
                                    </List>
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><RiFlightTakeoffLine size={25} className="text-sky-800"/>September
                                2015</Timeline.Time>
                            <Timeline.Title>Moved to München</Timeline.Title>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> May 2015 - September
                                2015</Timeline.Time>
                            <Timeline.Title>Senior Developer</Timeline.Title>
                            <Timeline.Body>
                                <p className="text-green-800">ShopWings GmbH</p>
                                <p className="italic">Berlin - DE</p>
                                <div className="mt-2">
                                    <List>
                                        <List.Item icon={HiCheckCircle}>Vagrant and puppet administrator</List.Item>
                                        <List.Item icon={HiCheckCircle}>Symfony2 development as for the web application
                                            as for API</List.Item>
                                        <List.Item icon={HiCheckCircle}>TDD for API – PHPUnit - Raml</List.Item>
                                        <List.Item icon={HiCheckCircle}>Senior PHP Backend Developer: Zend, Yii,
                                            PhalconPHP</List.Item>
                                        <List.Item icon={HiCheckCircle}>Frontend developer: Javascript, Less, Css3,
                                            HTML</List.Item>
                                    </List>
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><RiFlightTakeoffLine size={25} className="text-sky-800"/>April
                                2015</Timeline.Time>
                            <Timeline.Title>Moved to Berlin</Timeline.Title>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> November 2014 - April
                                2015</Timeline.Time>
                            <Timeline.Title>IT Lead Developer</Timeline.Title>
                            <Timeline.Body>
                                <p>Mazzmedia Srl</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><GiRing size={25} className="text-yellow-300"/>February 2015</Timeline.Time>
                            <Timeline.Title>Got married</Timeline.Title>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><GiNewBorn size={25} className="text-red-300"/>September 2014</Timeline.Time>
                            <Timeline.Title>My daughter Mia born</Timeline.Title>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> May 2014 - November
                                2014</Timeline.Time>
                            <Timeline.Title>Senior Developer</Timeline.Title>
                            <Timeline.Body>
                                <p>DServiceMedia @ Il Sole 24 Ore</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> October 2015 - March
                                2017</Timeline.Time>
                            <Timeline.Title>Software Developer</Timeline.Title>
                            <Timeline.Body>
                                <p>ContactLab s.r.l.</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> May 2012 - January
                                2013</Timeline.Time>
                            <Timeline.Title>Senior Web developer</Timeline.Title>
                            <Timeline.Body>
                                <p>Mozart s.p.a.</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> February 2007 - February 2013</Timeline.Time>
                            <Timeline.Title>Owner and CTO</Timeline.Title>
                            <Timeline.Body>
                                <p>Ridesoft</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><FaUserGraduate size={20} className="text-yellow-400"/> May
                                2006</Timeline.Time>
                            <Timeline.Title>Development Consultant SAP SDK</Timeline.Title>
                            <Timeline.Body>
                                <p>SAP</p>
                                <p>Certification ID: 0004057568</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> September 2006 - March
                                2011</Timeline.Time>
                            <Timeline.Title>Analyst and Programmer involved also in Database for 2.0 web
                                application</Timeline.Title>
                            <Timeline.Body>
                                <p>Mie Cube @ Doctor Shop</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><FaUserGraduate size={20} className="text-yellow-400"/> April
                                2006</Timeline.Time>
                            <Timeline.Title>Bachelor's degree in Informatics</Timeline.Title>
                            <Timeline.Body>
                                <p>Bicocca University</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> February 2002 - December 2012</Timeline.Time>
                            <Timeline.Title>Analyst, Programmer and Software Developer</Timeline.Title>
                            <Timeline.Body>
                                <p>BMFactory</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> April 2005 - October
                                2005</Timeline.Time>
                            <Timeline.Title>Programmer</Timeline.Title>
                            <Timeline.Body>
                                <p>Easytech S.r.l.</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><MdWork size={20} className="text-green-800"/> 2000 - 2002</Timeline.Time>
                            <Timeline.Title>Systemist</Timeline.Title>
                            <Timeline.Body>
                                <p>Marketing & Telematica</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                    <Timeline.Item>
                        <Timeline.Point/>
                        <Timeline.Content>
                            <Timeline.Time><FaUserGraduate size={20} className="text-yellow-400"/> June
                                2000</Timeline.Time>
                            <Timeline.Title>Scientific Gymnasium degree</Timeline.Title>
                            <Timeline.Body>
                                <p>Scientific Gymnasium</p>
                                <p>Milan - IT</p>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                </Timeline>
            </div>
            <div className="md:flex md:fixed md:top-40 md:right-20">
                <Link to="https://www.linkedin.com/in/mauriziobrioschi/" target="_blank" className="hidden md:inline">
                    <BiLogoLinkedinSquare size={40} className="text-blue-800 mr-5"/></Link>
                <Link to="https://github.com/mbrioski" target="_blank"><FaGithub size={40}
                                                                                 className="mr-5 hidden md:inline"/></Link>
                <a href="/files/CVEU_2024.pdf" download="CV_MaurizioBrioschi.pdf"><RiFileDownloadFill
                    className="cursor-pointer text-right hidden md:inline" size={40}
                /></a>
            </div>
        </>

    );
}

export default AboutPage;