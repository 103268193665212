import Nav from "./components/Nav";
import {Outlet} from "react-router-dom";
import {Footer} from "flowbite-react";

function RootLayout() {
    return (
        <>
            <div className="container mx-auto">
                <Nav/>
                <Outlet/>
                <Footer container>
                    <Footer.Copyright href="#" by="Maurizio Brioschi™" year={2024} />

                    <Footer.LinkGroup>
                        <Footer.Link href="/">Home</Footer.Link>
                        <Footer.Link href="/about">About</Footer.Link>
                        <Footer.Link href="/services">Services</Footer.Link>
                        <Footer.Link href="/references">References</Footer.Link>
                        <Footer.Link href="/contacts">Contacts</Footer.Link>
                    </Footer.LinkGroup>
                </Footer>
            </div>
        </>
    );
}

export default RootLayout;