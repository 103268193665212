import data from "./data/rating.json";
import {Accordion, Breadcrumb} from "flowbite-react";
import {MdOutlineAutoStories} from "react-icons/md";
import {HiHome} from "react-icons/hi";
import {RiFileDownloadFill} from "react-icons/ri";
import ReactCountryFlag from "react-country-flag";
import {Link} from "react-router-dom";
const ReferenceSection = () => {
    let references = data.toReversed().map((item) => {
        if (item.hasOwnProperty("attach")) {
            return (<Accordion.Panel key={item.author}>
                    <Accordion.Title><ReactCountryFlag
                        countryCode={item.lang}/> {item.author}, {item.author_role}@{item.author_company}
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500">{item.rating}</p>
                        <p>
                            <Link to={`/references/${item.attach}`} target="_blank" download><RiFileDownloadFill className="cursor-pointer" size={40}/></Link>
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
            );
        } else {
            return (
                <Accordion.Panel key={item.author}>
                    <Accordion.Title><ReactCountryFlag
                        countryCode={item.lang}/> {item.author}, {item.author_role}@{item.author_company}</Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500">{item.rating}</p>
                    </Accordion.Content>
                </Accordion.Panel>
            );
        }
    });
    return (
        <>
            <Breadcrumb aria-label="Solid background breadcrumb example"
                        className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                <Breadcrumb.Item href="/" icon={HiHome}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/references">References</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="font-impact text-3xl mb-5 mt-5 ml-2"><MdOutlineAutoStories/>My references</h1>
            <div className="mb-5">
                <Accordion>
                    {references}
                </Accordion>
            </div>

        </>
    );
}

export default ReferenceSection;