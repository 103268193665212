import data from "./data/rating.json";
import HomeRating from "./HomeRating";

const RatingSection = () => {
    let indexes = [];
    do {
        let index = Math.floor(Math.random() * data.length);
        if (!indexes.includes(index)) {
            indexes.push(index);
        }
    } while (indexes.length <= 3);
    return (
        <>
                <HomeRating
                    author={data[indexes[0]].author}
                    author_role={data[indexes[0]].author_role}
                    author_company={data[indexes[0]].author_company}
                    rating={data[indexes[0]].rating}
                    attach={data[indexes[0]].attach}
                />
                <HomeRating
                    author={data[indexes[1]].author}
                    author_role={data[indexes[1]].author_role}
                    author_company={data[indexes[1]].author_company}
                    rating={data[indexes[1]].rating}
                    attach={data[indexes[1]].attach}
                />
                <HomeRating
                    author={data[indexes[2]].author}
                    author_role={data[indexes[2]].author_role}
                    author_company={data[indexes[2]].author_company}
                    rating={data[indexes[2]].rating}
                    attach={data[indexes[2]].attach}
                />
        </>
    );
}

export default RatingSection;