import WorkCard from "../components/WorkCard";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { TbTruckDelivery } from "react-icons/tb";
function WorksPage() {
    return(
        <>
            <Breadcrumb aria-label="Solid background breadcrumb example" className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                <Breadcrumb.Item href="/" icon={HiHome}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/works">Works</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <h1 className="font-impact text-3xl mb-5 mt-5"><TbTruckDelivery/>I worked and developed</h1>
            </div>
            <WorkCard />
        </>

    );
}

export default WorksPage;