import { useEffect } from "react";
import quotes from "./data/quotes.json";
import {Carousel} from "flowbite-react";
import {Blockquote} from "flowbite-react";
import RatingSection from "../components/RatingSection";
import {FaBookOpen} from "react-icons/fa";
import DocumentMeta from 'react-document-meta';

function HomePage() {
    const quote =  quotes[Math.floor(Math.random() * quotes.length)];
    useEffect(() => {
        document.title = 'Maurizio Brioschi: Team leader, Software architect, Software engineer web and Digital transformation expert freelance';
    }, []);
    const meta = {
        meta: {
            title: '',
            name: {
                description: 'Empower your digital journey with a versatile freelance team led by experts in software architecture, engineering, and digital transformation. From strategic guidance to hands-on implementation, i will leads the way in navigating the complexities of modern web development. Discover how we can elevate your projects to new heights.',
                keywords: 'freelancer, team lead, software architect, software engineer, web development, digital transformation'
            }
        }
    };
    return (
        <>
            <DocumentMeta {...meta} />
            <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 mt-5 mb-5">
                <Carousel>
                    <div
                        className="grid grid-cols-1 bg-gradient-to-r from-gray-200 to-lime-100 md:h-full md:text-center md:items-center md:justify-center p-5 md:p-20">
                        <h1 className="font-impact text-lg md:text-4xl">Team leader</h1>
                        <h2 className="lg:text-2xl italic">Experienced and dedicated team leader adept at fostering
                            collaboration, driving results, and empowering team members to excel. Skilled in strategic
                            planning, problem-solving, and fostering a positive team culture to achieve organizational
                            goals.</h2>
                    </div>
                    <div
                        className="grid grid-cols-1 bg-gradient-to-r from-gray-200 to-lime-100 md:h-full md:text-center md:items-center md:justify-center p-5 md:p-20">
                        <h1 className="font-impact md:text-4xl">Software architect</h1>
                        <h2 className="lg:text-2xl italic">Dynamic software architect with a proven track record of designing
                            and implementing robust, scalable software solutions. Proficient in translating complex
                            business requirements into innovative architectural designs, driving technical excellence,
                            and ensuring alignment with industry best practices.</h2>
                    </div>
                    <div className="grid grid-cols-1 bg-gradient-to-r from-gray-200 to-lime-100 md:h-full md:text-center md:items-center md:justify-center p-5 md:p-20 ">
                        <h1 className="font-impact md:text-4xl ">Software engineer web</h1>
                        <h2 className="lg:text-2xl italic">Passionate software engineer with a strong foundation in coding,
                            testing, and debugging software applications. Experienced in collaborating with
                            cross-functional international teams to deliver high-quality solutions while continuously
                            learning and adapting to new technologies.</h2>
                    </div>
                </Carousel>
            </div>
            <div className="mt-5 mb-5">
                <figure className="mx-auto max-w-screen-md text-center">
                    <svg
                        className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 14"
                    >
                        <path
                            d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
                    </svg>
                    <Blockquote>
                        <p className="text-xl font-medium text-gray-800">
                            "{quote.quote}"
                        </p>
                    </Blockquote>
                    <figcaption className="mt-6 flex items-center justify-center space-x-3">
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <cite className="pr-3 font-medium text-gray-900 dark:text-white">{quote.author}</cite>
                        </div>
                    </figcaption>
                </figure>
            </div>
            <h1 className="font-impact text-3xl  mt-10 mb-2 ml-2">Stories about me</h1>
            <a
                href="/references"
                className="ml-2 mr-3 inline-flex items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200"
            >
                <FaBookOpen className="mr-2 h-4 w-4"/>
                Read all
            </a>
            <div className="md:flex md:flex-row mt-5 mb-5">
                <RatingSection/>
            </div>
        </>

    );
}

export default HomePage;