import ReferenceSection from "../components/ReferenceSection";
import DocumentMeta from 'react-document-meta';
import {useEffect} from "react";

function ReferencesPage() {
    useEffect(() => {
        document.title = 'Maurizio Brioschi references: check what people say about me and companies references';
    }, []);
    const meta = {
        meta: {
            name: {
                description: 'References and stories about me, what was done well during the years',
                keywords: 'references, career, jobs, companies, people, stories'
            }
        }
    };
    return (
        <>
            <DocumentMeta {...meta} />
            <ReferenceSection/>
        </>
    );
}

export default ReferencesPage;