import {Accordion, Breadcrumb} from "flowbite-react";
import {HiHome} from "react-icons/hi";
import {MdMiscellaneousServices} from "react-icons/md";
import DocumentMeta from 'react-document-meta';
import {useEffect} from "react";
import ReactCountryFlag from "react-country-flag";
import {Link} from "react-router-dom";

function ServicesPage() {
    useEffect(() => {
        document.title = 'Maurizio Brioschi services: team leader, software architect, software engineer web, digital transformation expert';
    }, []);
    const meta = {
        meta: {
            name: {
                description: 'Hire me as team lead, software architect or software engineer and let\'s work together to unlock the full potential of your software solutions on the road to digital transformation',
                keywords: 'team leader, software architect, software engineer web, digital transformation expert'
            }
        }
    };
    return (
        <>
            <DocumentMeta {...meta} />
            <Breadcrumb aria-label="Solid background breadcrumb example"
                        className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                <Breadcrumb.Item href="/" icon={HiHome}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
            </Breadcrumb>
            <div className="mb-2 flex flex-row">
                <div className="basis-11/12">
                    <h1 className="font-impact text-3xl mb-2 mt-5 ml-2"><MdMiscellaneousServices/> I can work as</h1>
                </div>
                <div className="basis-1/12 text-right">
                    <Link to="/servizi"><ReactCountryFlag countryCode="IT"/></Link>
                </div>
            </div>
                <div>
                    <Accordion collapseAll>
                        <Accordion.Panel key="team_lead">
                            <Accordion.Title>
                                Team Leader
                            </Accordion.Title>
                            <Accordion.Content>
                                <h1 className="text-3xl font-bold mb-4">Welcome to Your Next Level of Technical
                                    Leadership:
                                    Join Me as Your Dedicated Team Leader</h1>
                                <p className="mb-6">Are you searching for a seasoned professional to lead your technical
                                    projects to success? Look no further. I offer expert technical leadership services
                                    tailored to your unique needs. As your dedicated team leader, I bring years of
                                    experience and a proven track record of delivering exceptional results. Here's what
                                    I
                                    bring to the table:</p>

                                <h2 className="text-2xl font-bold mb-2">Comprehensive Technical Leadership:</h2>
                                <p className="mb-4">I provide end-to-end leadership for your technical projects, from
                                    initial planning to final delivery. With a keen eye for detail and a strategic
                                    mindset,
                                    I ensure that every aspect of your project is executed with precision and
                                    excellence.</p>

                                <h2 className="text-2xl font-bold mb-2">Expert Guidance and Support:</h2>
                                <p className="mb-4">As your team leader, I offer expert guidance and support to your
                                    team
                                    members, helping them navigate challenges, make informed decisions, and achieve
                                    their
                                    full potential. My hands-on approach fosters collaboration and empowers your team to
                                    excel.</p>

                                <h2 className="text-2xl font-bold mb-2">Customized Solutions for Your Needs:</h2>
                                <p className="mb-4">I understand that every project is unique. That's why I tailor my
                                    approach to meet your specific goals and requirements. Whether you need to
                                    streamline
                                    processes, improve efficiency, or overcome technical obstacles, I have the expertise
                                    to
                                    help you succeed.</p>

                                <h2 className="text-2xl font-bold mb-2">Risk Mitigation and Problem-Solving:</h2>
                                <p className="mb-4">I proactively identify and mitigate risks to ensure smooth project
                                    execution. With a focus on problem-solving and contingency planning, I keep your
                                    projects on track and minimize disruptions.</p>

                                <h2 className="text-2xl font-bold mb-2">Continuous Improvement:</h2>
                                <p className="mb-6">I believe in the power of continuous improvement. I regularly
                                    evaluate
                                    project performance, gather feedback, and implement lessons learned to optimize
                                    processes and drive success.</p>

                                <h2 className="text-2xl font-bold mb-2">Why Choose Me as Your Team Leader?</h2>
                                <ul className="list-disc ml-6 mb-6">
                                    <li><strong>Proven Expertise:</strong> With a solid background in technical
                                        leadership,
                                        I bring valuable skills and insights to your projects.
                                    </li>
                                    <li><strong>Dedication to Success:</strong> I am committed to delivering results
                                        that
                                        exceed your expectations and contribute to your long-term success.
                                    </li>
                                    <li><strong>Collaborative Approach:</strong> I work closely with your team to build
                                        trust, foster collaboration, and achieve shared goals.
                                    </li>
                                    <li><strong>Client Satisfaction:</strong> My ultimate goal is your satisfaction. I
                                        go
                                        above and beyond to ensure that you are thrilled with the outcomes of your
                                        projects.
                                    </li>
                                </ul>

                                <h2 className="text-2xl font-bold mb-2">Ready to Take Your Projects to the Next
                                    Level?</h2>
                                <p className="mb-6">Partner with me as your team leader, and let's embark on a journey
                                    of
                                    success together. Contact me today to discuss how I can support your technical
                                    projects
                                    and help you achieve your goals.</p>
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel key="software_architect">
                            <Accordion.Title>
                                Software Architect
                            </Accordion.Title>
                            <Accordion.Content>
                                <h1 className="text-3xl font-bold mb-4">Unlock Your Software's Potential: Hire Me as
                                    Your
                                    Software Architect</h1>
                                <p className="mb-6">Are you looking to build scalable, maintainable, and efficient
                                    software
                                    solutions? Look no further. As a seasoned software architect, I offer expert
                                    services
                                    tailored to your unique needs. Here's how I can help:</p>
                                <h2 className="text-2xl font-bold mb-2">Comprehensive Software Architecture:</h2>
                                <p className="mb-4">I design comprehensive software architectures that align with your
                                    business goals and technical requirements. From system design to implementation, I
                                    ensure that your software solution is robust, scalable, and future-proof.</p>
                                <h2 className="text-2xl font-bold mb-2">Technology Selection and Evaluation:</h2>
                                <p className="mb-4">I help you choose the right technologies and tools for your project,
                                    considering factors such as scalability, performance, security, and
                                    cost-effectiveness.
                                    I conduct thorough evaluations and provide recommendations to ensure optimal
                                    technology
                                    stack selection.</p>
                                <h2 className="text-2xl font-bold mb-2">Scalability and Performance Optimization:</h2>
                                <p className="mb-4">I architect solutions that can scale with your business needs and
                                    handle
                                    increased loads efficiently. Through performance optimization techniques and
                                    architectural best practices, I ensure that your software performs optimally under
                                    all
                                    conditions.</p>
                                <h2 className="text-2xl font-bold mb-2">Security and Compliance:</h2>
                                <p className="mb-4">I prioritize security and compliance in every aspect of the software
                                    architecture. From data encryption to access control mechanisms, I implement robust
                                    security measures to protect your valuable assets and ensure regulatory
                                    compliance.</p>
                                <h2 className="text-2xl font-bold mb-2">Team Collaboration and Leadership:</h2>
                                <p className="mb-4">I collaborate closely with your development team, providing
                                    leadership,
                                    guidance, and mentorship throughout the software development lifecycle. I foster a
                                    collaborative environment where team members can thrive and deliver their best
                                    work.</p>
                                <h2 className="text-2xl font-bold mb-2">Continuous Improvement and Innovation:</h2>
                                <p className="mb-6">I believe in the power of continuous improvement and innovation. I
                                    stay
                                    updated on the latest industry trends and emerging technologies to ensure that your
                                    software remains cutting-edge and competitive in the ever-evolving market.</p>
                                <h2 className="text-2xl font-bold mb-2">Why Choose Me as Your Software Architect?</h2>
                                <ul className="list-disc ml-6 mb-6">
                                    <li><strong>Extensive Experience:</strong> With years of experience in software
                                        architecture, I bring a wealth of knowledge and expertise to your projects.
                                    </li>
                                    <li><strong>Customized Solutions:</strong> I tailor my approach to meet your
                                        specific
                                        needs and goals, ensuring that you get the best possible outcomes.
                                    </li>
                                    <li><strong>Commitment to Excellence:</strong> I am committed to delivering software
                                        solutions of the highest quality that exceed your expectations.
                                    </li>
                                    <li><strong>Client Satisfaction:</strong> My ultimate goal is your satisfaction. I
                                        work
                                        closely with you to understand your requirements and deliver solutions that meet
                                        or
                                        exceed your expectations.
                                    </li>
                                </ul>
                                <h2 className="text-2xl font-bold mb-2">Ready to Elevate Your Software Solutions?</h2>
                                <p className="mb-6">Hire me as your software architect, and let's work together to
                                    unlock
                                    the full potential of your software solutions. Contact me today to discuss your
                                    project
                                    requirements and how I can help you achieve your goals.</p>
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel key="software_engineer">
                            <Accordion.Title>
                                Software Engineer and developer
                            </Accordion.Title>
                            <Accordion.Content>
                                <h1 className="text-3xl font-bold mb-4">Empower Your Projects with Expert PHP, React.js,
                                    and
                                    Vue.js Development</h1>
                                <p className="mb-6">Are you in need of a skilled software engineer and developer
                                    proficient
                                    in PHP, React.js, and Vue.js to enhance your projects? Look no further. I offer
                                    specialized services tailored to your needs in these technologies. Here's how I can
                                    contribute to your success:</p>

                                <h2 className="text-2xl font-bold mb-2">Custom Web Development:</h2>
                                <p className="mb-4">I specialize in custom web development using PHP, React.js, and
                                    Vue.js
                                    to create dynamic and interactive web applications. Whether you need a robust
                                    backend
                                    system, a responsive front-end interface, or a full-stack solution, I have the
                                    expertise
                                    to deliver high-quality, scalable, and user-friendly applications.</p>

                                <h2 className="text-2xl font-bold mb-2">Full-Stack Development:</h2>
                                <p className="mb-4">I am proficient in both front-end and back-end development, allowing
                                    me
                                    to handle all aspects of software development. From database design to user
                                    interface
                                    implementation, I have the skills to develop comprehensive solutions that meet your
                                    specific requirements.</p>

                                <h2 className="text-2xl font-bold mb-2">React.js and Vue.js Development:</h2>
                                <p className="mb-4">I specialize in building dynamic and responsive user interfaces
                                    using
                                    React.js and Vue.js. Whether you need to develop single-page applications,
                                    progressive
                                    web apps, or interactive UI components, I leverage the power of these frameworks to
                                    create engaging user experiences.</p>

                                <h2 className="text-2xl font-bold mb-2">Custom Plugin and Component Development:</h2>
                                <p className="mb-4">I develop custom plugins and components for PHP-based applications
                                    as
                                    well as React.js and Vue.js projects. Whether you need to extend the functionality
                                    of
                                    existing systems or integrate third-party services, I can create tailored solutions
                                    to
                                    meet your specific needs.</p>

                                <h2 className="text-2xl font-bold mb-2">Quality Assurance and Testing:</h2>
                                <p className="mb-4">I prioritize quality assurance throughout the development process,
                                    conducting thorough testing to identify and resolve issues before they impact your
                                    users. I employ automated testing techniques and best practices to ensure that your
                                    software is reliable and bug-free.</p>

                                <h2 className="text-2xl font-bold mb-2">Collaborative Approach:</h2>
                                <p className="mb-4">I believe in transparent communication and collaboration with my
                                    clients. I work closely with you to understand your requirements, provide regular
                                    updates, and incorporate your feedback to ensure that the final product meets your
                                    expectations.</p>

                                <h2 className="text-2xl font-bold mb-2">Continuous Learning and Improvement:</h2>
                                <p className="mb-6">I am passionate about staying updated on the latest technologies and
                                    best practices in software development. I continually seek opportunities to enhance
                                    my
                                    skills and knowledge to deliver innovative solutions that drive your success.</p>

                                <h2 className="text-2xl font-bold mb-2">Why Choose Me as Your Software Engineer and
                                    Developer?</h2>
                                <ul className="list-disc ml-6 mb-6">
                                    <li><strong>Extensive Experience:</strong> With years of experience in software
                                        engineering and development, I bring a depth of expertise to your projects.
                                    </li>
                                    <li><strong>Specialized Skills:</strong> I specialize in PHP, React.js, and Vue.js
                                        development, offering specialized services tailored to your specific needs.
                                    </li>
                                    <li><strong>Customized Solutions:</strong> I tailor my services to meet your unique
                                        requirements, ensuring that you receive solutions that are perfectly aligned
                                        with
                                        your goals.
                                    </li>
                                    <li><strong>Reliability and Commitment:</strong> I am committed to delivering
                                        results of
                                        the highest quality on time and within budget.
                                    </li>
                                </ul>
                                <h2 className="text-2xl font-bold mb-2">Ready to Empower Your Projects?</h2>
                                <p className="mb-6">Hire me as your software engineer and developer, and let's
                                    collaborate
                                    to bring your ideas to life using PHP, React.js, and Vue.js. Contact me today to
                                    discuss
                                    your project requirements and how I can help you achieve your goals.</p>
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel key="digital_transformation_expert">
                            <Accordion.Title>
                                Digital transformation expert
                            </Accordion.Title>
                            <Accordion.Content>
                                <h1 className="text-3xl font-bold mb-4">Unlock Your Potential with Expert Digital
                                    Transformation
                                    and Agile Services</h1>

                                <p className="mb-6">Are you ready to embrace digital transformation and Agile
                                    methodologies
                                    to
                                    drive innovation and growth in your organization? Look no further. I offer expert
                                    services
                                    tailored to help you navigate the complexities of digital transformation and
                                    implement
                                    Agile
                                    practices effectively. Here's how I can help:</p>

                                <h2 className="text-2xl font-bold mb-2">Digital Transformation Strategy:</h2>
                                <p className="mb-4">I specialize in developing comprehensive digital transformation
                                    strategies
                                    that align with your business objectives and drive meaningful change. From assessing
                                    your
                                    current state to defining a roadmap for transformation, I work closely with you to
                                    ensure
                                    that your digital initiatives deliver maximum value.</p>

                                <h2 className="text-2xl font-bold mb-2">Agile Implementation and Coaching:</h2>
                                <p className="mb-4">I have extensive experience in implementing Agile methodologies such
                                    as
                                    Scrum, Kanban, and Lean within organizations of all sizes. Whether you're new to
                                    Agile
                                    or
                                    looking to optimize your existing processes, I provide hands-on coaching and support
                                    to
                                    help
                                    your teams embrace Agile principles and practices.</p>

                                <h2 className="text-2xl font-bold mb-2">Digital Innovation and Disruption:</h2>
                                <p className="mb-4">I help organizations harness the power of digital technologies to
                                    drive
                                    innovation and disrupt traditional business models. From adopting cloud computing
                                    and
                                    artificial intelligence to leveraging data analytics and IoT, I empower you to stay
                                    ahead of
                                    the curve and capitalize on emerging opportunities.</p>

                                <h2 className="text-2xl font-bold mb-2">Organizational Change Management:</h2>
                                <p className="mb-4">I understand that digital transformation is not just about
                                    technology—it's
                                    also about people and processes. I provide change management support to help your
                                    organization adapt to new ways of working, overcome resistance to change, and foster
                                    a
                                    culture of continuous improvement.</p>

                                <h2 className="text-2xl font-bold mb-2">Collaborative Approach:</h2>
                                <p className="mb-4">I believe in working collaboratively with your teams and
                                    stakeholders to
                                    co-create solutions that drive meaningful results. I facilitate workshops, meetings,
                                    and
                                    retrospectives to ensure alignment, transparency, and accountability throughout the
                                    transformation journey.</p>

                                <h2 className="text-2xl font-bold mb-2">Continuous Learning and Improvement:</h2>
                                <p className="mb-6">I am passionate about staying updated on the latest trends and best
                                    practices in digital transformation and Agile methodologies. I continually seek
                                    opportunities to enhance my skills and knowledge to deliver innovative solutions
                                    that
                                    drive
                                    your organization's success.</p>

                                <h2 className="text-2xl font-bold mb-2">Why Choose Me for Your Digital Transformation
                                    and
                                    Agile
                                    Initiatives?</h2>
                                <ul className="list-disc ml-6 mb-6">
                                    <li><strong>Expertise and Experience:</strong> With a proven track record in digital
                                        transformation and Agile, I bring valuable insights and expertise to your
                                        projects.
                                    </li>
                                    <li><strong>Customized Solutions:</strong> I tailor my services to meet your
                                        specific
                                        needs
                                        and goals, ensuring that you achieve the desired outcomes of your digital
                                        initiatives.
                                    </li>
                                    <li><strong>Collaborative Approach:</strong> I work closely with your teams and
                                        stakeholders
                                        to ensure alignment, transparency, and accountability throughout the
                                        transformation
                                        journey.
                                    </li>
                                    <li><strong>Commitment to Success:</strong> I am dedicated to your success and
                                        strive to
                                        deliver results of the highest quality on time and within budget.
                                    </li>
                                </ul>

                                <h2 className="text-2xl font-bold mb-2">Ready to Embrace Digital Transformation and
                                    Agile?</h2>
                                <p className="mb-6">Partner with me to unlock the full potential of your organization
                                    through
                                    digital transformation and Agile methodologies. Contact me today to discuss your
                                    goals
                                    and
                                    how I can help you achieve them.</p>
                            </Accordion.Content>
                        </Accordion.Panel>
                    </Accordion>
                </div>
        </>

    );
}

export default ServicesPage;